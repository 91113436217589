import _ from 'lodash'
import { getOtherApplicantIndex, isPrimaryApplicant } from './methods'
import {
  APPLICATION_STATUS_SUBMITTED_TO_LENDER,
  LABEL_BANK_STATEMENT_SECURE,
} from '~/constants/applications'
export default {
  setNewApplication(state, vrgs) {
    state.application = vrgs
  },
  setNewCloneApplication(state, vrgs) {
    state.cloneApplication = vrgs
  },
  setPrimaryApplication(state, args) {
    state.application.primaryApplicantData = args
  },
  setPrimaryApplicantDocuments(state, args) {
    state.application.primaryApplicantData.documents = args
  },
  setSecondaryApplicantDocuments(state, args) {
    const { documentData, secondaryIndex } = args
    state.application.otherApplicants[secondaryIndex].documents = documentData
  },
  setSharedDocuments(state, args) {
    state.application.sharedDocuments = args
  },
  setApplicationStatus(state, args) {
    state.application.status = args
    state.application.statusChangedAt = new Date().toISOString()
  },
  setApplicationReasonTypes(state, args) {
    state.application = args
  },
  setApplicationsAddresses(state, args) {
    state.application.primaryApplicantData.addresses = args
  },
  setAppLoaded(state, args) {
    state.isLoading = args
  },
  setApplicationType(state, args) {
    state.application.applicationType = args
  },
  setApplicationLoanType(state, args) {
    state.application.loanType = args
  },
  setEmployments(state, args) {
    state.application.primaryApplicantData.employments = args
  },
  setSecondaryEmployments(state, { employments, activeApplicantIndex }) {
    state.application.otherApplicants[activeApplicantIndex].employments =
      employments
  },
  setExpenses(state, args) {
    state.application.primaryApplicantData.expenses = args.primaryData.expenses
    if (
      args.secondaryData &&
      args.secondaryData[0] &&
      args.secondaryData[0].expenses
    ) {
      state.application.otherApplicants[0].expenses =
        args.secondaryData[0].expenses
    }
  },
  setDefaultExpense(state, args) {
    state.application.primaryApplicantData.expenses = args
  },
  setDefaultSecondaryExpense(state, args) {
    if (state.application.otherApplicants.length > 0)
      state.application.otherApplicants[0].expenses = args
  },
  setExpenseDisclaimer(state, args) {
    state.application.primaryApplicantData.expensesDisclaimer = args
  },
  setIncomes(state, args) {
    state.application.primaryApplicantData = args.primaryData
    state.application.otherApplicants = args.secondaryData
  },
  setSelectedDocuments(state, args) {
    const findInd =
      state.application.primaryApplicantData.selectedDocuments.findIndex(
        (doc) => doc.purpose === LABEL_BANK_STATEMENT_SECURE
      )
    if (findInd === -1) {
      state.application.primaryApplicantData.selectedDocuments = args
    } else {
      const data =
        state.application.primaryApplicantData.selectedDocuments[findInd]
      state.application.primaryApplicantData.selectedDocuments = args
      state.application.primaryApplicantData.selectedDocuments.push(data)
    }
  },
  setOtherSelectedDocuments(state, args) {
    const { selectedDocuments, secondaryIndex } = args
    const findInd = state.application.otherApplicants[
      secondaryIndex
    ].selectedDocuments.findIndex(
      (doc) => doc.purpose === LABEL_BANK_STATEMENT_SECURE
    )

    if (findInd === -1) {
      state.application.otherApplicants[secondaryIndex].selectedDocuments =
        selectedDocuments
    } else {
      const data =
        state.application.otherApplicants[secondaryIndex].selectedDocuments[
          findInd
        ]
      state.application.otherApplicants[secondaryIndex].selectedDocuments =
        selectedDocuments
      state.application.otherApplicants[secondaryIndex].selectedDocuments.push(
        data
      )
    }
  },
  setSharedSelectedDocuments(state, args) {
    const findInd = state.application.selectedDocuments.findIndex(
      (doc) => doc.purpose === LABEL_BANK_STATEMENT_SECURE
    )
    if (findInd === -1) {
      state.application.selectedDocuments = args
    } else {
      const data = state.application.selectedDocuments[findInd]
      state.application.selectedDocuments = args
      state.application.selectedDocuments.push(data)
    }
  },

  setSelectedDocument(state, args) {
    const findInd =
      state.application.primaryApplicantData.selectedDocuments.findIndex(
        (doc) => doc.purpose === args.purpose
      )
    if (findInd === -1) {
      state.application.primaryApplicantData.selectedDocuments.push(args)
    }
  },
  setOtherSelectedDocument(state, args) {
    const { selectedDocuments, secondaryIndex } = args
    const findInd = state.application.otherApplicants[
      secondaryIndex
    ].selectedDocuments.findIndex(
      (doc) => doc.purpose === selectedDocuments.purpose
    )
    if (findInd === -1) {
      state.application.otherApplicants[secondaryIndex].selectedDocuments.push(
        selectedDocuments
      )
    }
  },
  setSharedSelectedDocument(state, args) {
    const findInd = state.application.selectedDocuments.findIndex(
      (doc) => doc.purpose === args.purpose
    )
    if (findInd === -1) {
      state.application.selectedDocuments.push(args)
    }
  },

  updateSelectedDocument(state, args) {
    const findInd =
      state.application.primaryApplicantData.selectedDocuments.findIndex(
        (doc) => {
          return doc.purpose === args || doc.name === args
        }
      )

    if (findInd !== -1)
      state.application.primaryApplicantData.selectedDocuments.splice(
        findInd,
        1
      )

    const remainingDocs = [
      ...state.application.primaryApplicantData.documents.filter(
        (doc) => doc.purpose !== args && doc.name !== args
      ),
    ]

    state.application.primaryApplicantData.documents = remainingDocs
  },
  updateSelectedBankStatementDocument(state, args) {
    state.application.primaryApplicantData.bankStatementRequestedAt = ''
    const findInd =
      state.application.primaryApplicantData.selectedDocuments.findIndex(
        (doc) => {
          return doc.purpose === args || doc.name === args
        }
      )
    if (findInd !== -1)
      state.application.primaryApplicantData.selectedDocuments.splice(
        findInd,
        1
      )
    const remainingDocs = [
      ...state.application.primaryApplicantData.documents.filter(
        (doc) => doc.purpose !== args
      ),
    ]
    state.application.primaryApplicantData.documents = remainingDocs
  },
  removeOtherSelectedBankStatementDocument(state, args) {
    const { index, documentType } = args
    state.application.otherApplicants[index].bankStatementRequestedAt = ''
    state.application.otherApplicants[index].selectedDocuments =
      state.application.otherApplicants[index].selectedDocuments.filter(
        (ele) => {
          if (ele.purpose !== documentType && ele.purpose != 'Other') {
            return ele
          } else if (ele.purpose === 'Other' && ele.name !== documentType) {
            return ele
          }
        }
      )
    state.application.otherApplicants[index].documents =
      state.application.otherApplicants[index].documents.filter((ele) => {
        if (ele.purpose !== documentType && ele.purpose != 'Other') {
          return ele
        } else if (ele.purpose === 'Other' && ele.name !== documentType) {
          return ele
        }
      })
  },
  updateOtherSelectedDocument(state, args) {
    const { index, documentType } = args
    state.application.otherApplicants[index].selectedDocuments =
      state.application.otherApplicants[index].selectedDocuments.filter(
        (ele) => {
          if (ele.name !== documentType && ele.purpose != 'Other') {
            return ele
          } else if (ele.purpose === 'Other' && ele.name !== documentType) {
            return ele
          }
        }
      )

    state.application.otherApplicants[index].documents =
      state.application.otherApplicants[index].documents.filter((ele) => {
        if (ele.name !== documentType && ele.purpose != 'Other') {
          return ele
        } else if (ele.purpose === 'Other' && ele.name !== documentType) {
          return ele
        }
      })
  },
  updateSharedSelectedDocument(state, args) {
    const findInd = state.application.selectedDocuments.findIndex((doc) => {
      return doc.purpose === args || doc.name === args
    })

    if (findInd !== -1) state.application.selectedDocuments.splice(findInd, 1)
    const remainingDocs = [
      ...state.application.sharedDocuments.filter(
        (doc) => doc.purpose !== args
      ),
    ]
    state.application.sharedDocuments = remainingDocs
  },
  setDocumentData(state, args) {
    state.application.primaryApplicantData.documents.push(args)
  },
  removeDocumentData(state, args) {
    state.application.primaryApplicantData.documents.map(
      // eslint-disable-next-line array-callback-return
      (docToRemove, index) => {
        // eslint-disable-next-line no-unused-expressions
        docToRemove.url === args
          ? state.application.primaryApplicantData.documents.splice(index, 1)
          : true
      }
    )
  },
  setOtherDocumentData(state, args) {
    const { documents, index } = args
    state.application.otherApplicants[index].documents.push(documents)
  },
  removeOtherDocumentData(state, args) {
    const { url, index: secondaryIndex } = args
    state.application.otherApplicants[secondaryIndex].documents.map(
      // eslint-disable-next-line array-callback-return
      (docToRemove, index) => {
        // eslint-disable-next-line no-unused-expressions
        docToRemove.url === url
          ? state.application.otherApplicants[secondaryIndex].documents.splice(
              index,
              1
            )
          : true
      }
    )
  },
  setSharedDocumentData(state, args) {
    state.application.sharedDocuments.push(args)
  },
  removeSharedDocumentData(state, args) {
    state.application.sharedDocuments.map(
      // eslint-disable-next-line array-callback-return
      (docToRemove, index) => {
        // eslint-disable-next-line no-unused-expressions
        docToRemove.url === args
          ? state.application.sharedDocuments.splice(index, 1)
          : true
      }
    )
  },
  setPrimaryBankStatement(state) {
    state.application.primaryApplicantData.bankStatementRequestedAt =
      new Date().toISOString()
  },
  setSecondaryBankStatement(state, args) {
    const index = args
    state.application.otherApplicants[index].bankStatementRequestedAt =
      new Date().toISOString()
  },
  setOtherApplicantData(state, args) {
    state.application.otherApplicants = args
  },

  updateApplicationPurpose(state, args) {
    state.application.purposeOfObtainingIncreasingOrRefinanceCredit = args
  },
  copyPrimaryApplicantAddressData(state) {
    if (
      state.application.businessData?.isBusinessAddressSameAsResidence ||
      state.application.businessData?.isBusinessAddressSameAsResidence === null
    ) {
      state.application.businessData.isBusinessAddressSameAsResidence = false
      state.application.businessData.billingAddress =
        state.application.primaryApplicantData.addresses[0]
    }
  },
  updateLoanType(state, args) {
    state.application.loanType = args
  },
  updateCertaintyStage(state, args) {
    state.application.certaintyStage = args
  },
  updateApplicationMonthlyRepayment(state, args) {
    state.application.monthlyRepaymentBudget = args
  },
  updateApplicationExpectedLoanFeatures(state, args) {
    state.application.customerExpectedLoanFeatures = args
  },
  updateIsCreditRequiredForAdditionalCosts(state, args) {
    state.application.isCreditRequiredForAdditionalCosts = args
  },
  updateIsAwareOfFinancingCosts(state, args) {
    state.application.isAwareOfFinancingCosts = args
  },
  updateVehicles(state, args) {
    state.application.vehicle = args
  },
  setPrimaryAssets(state, args) {
    state.application.primaryApplicantData.assets = args
  },
  setSecondaryAssets(state, args) {
    if (
      Array.isArray(state.application.otherApplicants) &&
      state.application.otherApplicants.length
    ) {
      state.application.otherApplicants[0].assets = args
    }
  },
  setSecondaryAssetsByIndex(state, args) {
    const { assets, index } = args
    state.application.otherApplicants[index].assets = assets
  },
  setPrimaryLiabilities(state, args) {
    state.application.primaryApplicantData.liabilities = args
  },
  setSecondaryLiabilities(state, args) {
    if (
      Array.isArray(state.application.otherApplicants) &&
      state.application.otherApplicants.length
    ) {
      state.application.otherApplicants[0].liabilities = args
    }
  },
  setCreditCommitments(state, args) {
    state.application.primaryApplicantData = {
      ...state.application.primaryApplicantData,
      ...args,
    }
  },
  setSecondaryLiabilitiesByIndex(state, args) {
    const { liabilities, index } = args
    state.application.otherApplicants[index].liabilities = liabilities
  },
  resetVehicle(state, args) {
    _.assign(state.application.vehicle, args)
  },
  setSecondaryApplication(state, args) {
    state.application.otherApplicants[0] = args
  },
  setNotesDetails(state, args) {
    state.application.notes[0] = args
  },
  setSecondaryApplicationByIndex(state, args) {
    const { index, application } = args
    state.application.otherApplicants[index] = application
  },
  setLeadSource(state, args) {
    state.leadSource = args
  },
  setLeadSourceSystem(state, args) {
    state.leadSourceSystem = args
  },
  setWebAppJourney(state, args) {
    state.webAppJourney = args
  },
  setLinks(state, args) {
    state.links = args
  },
  setSfLead(state, args) {
    state.sFLead = args
  },
  setSFOpportunity(state, args) {
    state.sFOpportunity = args
  },
  setReferrerAccount(state, args) {
    state.referrerAccount = args
  },
  setPartnerId(state, args) {
    state.partnerId = args
  },
  setAccounts(state, args) {
    state.accountData = args
  },
  submitLoanApplication(state) {
    state.application.status = APPLICATION_STATUS_SUBMITTED_TO_LENDER
    state.application.statusChangedAt = new Date().toISOString()
    state.application.loanDetails.submission.submittedAt =
      new Date().toISOString()
    state.hasLoanDetails = true
  },
  setInsurance(state, args) {
    state.application.loanDetails.settlement.insurance = args
  },
  setWarranty(state, args) {
    state.application.loanDetails.settlement.warranty = args
  },
  setAccountDetails(state, args) {
    state.application.loanDetails.lenderData = args
  },
  setBusinessDetails(state, args) {
    state.application.businessData = args
  },
  setBusinessAccountDetails(state, args) {
    state.application.businessData.accountant = args
  },
  setBusinessAddress(state, args) {
    state.application.businessData.billingAddress = args.billingAddress
    state.application.businessData.isBusinessAddressSameAsResidence =
      args.isBusinessAddressSameAsResidence
  },
  setNotesDetails(state, args) {
    state.application.notes[0] = args
  },
  setNotes(state, args) {
    state.application.notes.push(args)
  },
  updateNotes(state, args) {
    // eslint-disable-next-line array-callback-return
    state.application.notes.map((note) => {
      // eslint-disable-next-line no-unused-expressions
      if (note.type === args.type) {
        note.description = args.notes[0]?.description
        note.updatedAt = args.notes[0]?.updatedAt
      }
    })
  },
  setShareHolderData(state, args) {
    state.application.businessData.shareHolders = args
  },
  setBeneficiariesData(state, args) {
    state.application.businessData.beneficiaries = args
  },
  setOwner(state, args) {
    state.owner = args
  },
  clearOwner(state) {
    state.owner = {}
  },
  setPrimaryExpenses(state, args) {
    const { expenses } = args
    state.application.primaryApplicantData.expenses = expenses
  },
  setSecondaryExpenses(state, args) {
    const { expenses, index } = args
    state.application.otherApplicants[index] = expenses
  },
  setSecondaryExpensesByIndex(state, args) {
    const { expenses, index } = args
    state.application.otherApplicants[index].expenses = expenses
  },
  setConfirmableCredit(state, args) {
    const { isPrimary, hasConfirmableCredit, index } = args
    if (isPrimary) {
      state.application.primaryApplicantData.hasConfirmableCredit =
        hasConfirmableCredit
    } else {
      state.application.otherApplicants[index].hasConfirmableCredit =
        hasConfirmableCredit
    }
  },
  removeSpousalData(state) {
    const { primaryApplicantData } = state.application
    primaryApplicantData.hasPartnerIncome = false
    primaryApplicantData.partnerFirstName = ''
    primaryApplicantData.partnerLastName = ''
    primaryApplicantData.partnerNetIncome = 0
    primaryApplicantData.partnerNetIncomeFrequency = ''
    primaryApplicantData.partnerNotes = ''
  },
  setCreditEnquiries(state, { payload, applicantId }) {
    if (isPrimaryApplicant(state.application, applicantId)) {
      state.application.primaryApplicantData.creditEnquiries = payload
      return
    }
    const otherApplicantIndex = getOtherApplicantIndex(
      state.application,
      applicantId
    )
    otherApplicantIndex !== -1 &&
      (state.application.otherApplicants[otherApplicantIndex].creditEnquiries =
        payload)
  },
  setRefetchFile(state, { payload, applicantId }) {
    if (isPrimaryApplicant(state.application, applicantId)) {
      state.application.primaryApplicantData.creditEnquiries.creditFileUrl =
        payload
      return
    }
    const otherApplicantIndex = getOtherApplicantIndex(
      state.application,
      applicantId
    )
    otherApplicantIndex !== -1 &&
      (state.application.otherApplicants[
        otherApplicantIndex
      ].creditEnquiries.creditFileUrl = payload)
  },
  SetsettlementChecklistItem(state, { data }) {
    state.application.settlementChecklist = data
  },
  setSettlementNotes(state, { data }) {
    state.application.settlementNotes = data.settlementNotes
  },
  setInitialLeadFunnel(state, args) {
    state.initialLeadFunnel = args
  },
  setTotalNetSurplus(state, args) {
    state.totalNetSurplus = args
  },
  setSettlementStatus(state, { status }) {
    state.application.loanDetails.settlement.settlementStatus = status
  },
  setSettlementETA(state, { date }) {
    state.application.loanDetails.approval.settlementEta = date
  },
  setSellerDetails(state, data) {
    state.application.loanDetails.settlement.sellerDetails = data
  },
  setMatchesGenerated(state, data = []) {
    state.matchesGenerated = true
    state.generatedMatchesData = data
  },
  setlenderMatchesSettings(state, data = {}) {
    state.lenderMatchesSettings = data
  },
  setLenderMatchesApplicationMatchesForm(state, data = {}) {
    state.lenderMatchesApplicationMatchesForm = data
  },
  setMatchesGeneratedFalse(state) {
    state.matchesGenerated = false
    state.generatedMatchesData = []
  },
  setXeroInvoiceProps(state, data) {
    state.application.tpInvoiceId = data.tpInvoiceId
    state.application.tpInvoiceCreatedAt = data.tpInvoiceCreatedAt
    state.application.tpInvoiceCreationAttemptedAt =
      data.tpInvoiceCreationAttemptedAt
    state.application.tpInvoiceURL = data.tpInvoiceURL
    state.application.tpInvoiceErrorReason = data.tpInvoiceErrorReason
  },
}

import cloneDeep from 'lodash/cloneDeep'
import orderBy from 'lodash/orderBy'
import pick from 'lodash/pick'
import set from 'lodash/set'
import {
  DEFAULT_VEHICLE_GROUP_OBJECT,
  LABEL_CONSUMER_CAR_LOAN,
  LABEL_COMMERCIAL_CAR_LOAN,
  DEFAULT_VEHICLE_OBJECT,
  LABEL_PERSONAL_LOAN,
  REFERRAL_MODE_QUICK,
  REFERRAL_MODE_STANDARD,
} from '@/constants/referrals'
import { isAccountRelationActiveReferrer } from '~/helpers/user'
import { mapVRGForTable } from '~/helpers/referrals'
import {
  VEHICLE_TYPE_ASSET,
  VEHICLE_TYPE_TRADE_IN,
  VEHICLE_USE_BUSINESS,
  VEHICLE_USE_PERSONAL,
} from '~/constants/vehicles'
import {
  APPLICATION_REQUEST_TYPE_COMMERCIAL,
  APPLICATION_REQUEST_TYPE_CONSUMER,
} from '~/constants'
import { axiosErrorMessageExtractor } from '~/utils/error'
import {
  ADVANCED_SEARCH_CUSTOMER,
  ADVANCED_SEARCH_LOAN_DETAILS,
  ADVANCED_SEARCH_REFERRAL,
  ADVANCED_SEARCH_REFERRAL_FIRST,
} from '~/constants/applications'
var documentRequestSource
var source

export default {
  // eslint-disable-next-line no-empty-pattern
  async getBusinessSearchABN({}, { business }) {
    try {
      if (business !== '') {
        const searchQueryUrl = `businesses/search?query=${business}&excludeCancelled=true`
        const searchResult = await this.$axios.get(searchQueryUrl)

        return searchResult
      }
    } catch (error) {
      const err = error.response?.data.error || error
      console.error(err)
      this.$notify.error({
        title: 'Error',
        message: 'Error in getting models :(',
      })
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async getBusinessStructure({}, { abn }) {
    try {
      const searchQueryUrl = `businesses/search-by-abn?abn=${abn}`
      const searchResult = await this.$axios.get(searchQueryUrl)

      return searchResult
    } catch (error) {
      const err = error.response?.data.error || error
      console.error(err)
      this.$notify.error({
        title: 'Error',
        message: 'Error in getting models :(',
      })
    }
  },
  // TODO: merge this with the asset creation action (directly after this one)
  async createReferralVRG(
    { commit, rootState },
    { vehicleRequestGroup, isCommercial, isQuickReferral }
  ) {
    try {
      // Clone to prevent accidentally mutating it
      const form = cloneDeep(vehicleRequestGroup)
      const referrerNote =
        form.finance.application.primaryApplicantData.primaryApplicant
          .background
      let resCreateUser

      // CREATE OR UPDATE USER
      try {
        const createUserAPIUrl = `user/upsert`
        resCreateUser = await this.$axios.post(
          createUserAPIUrl,
          pick(form.finance.application.primaryApplicantData.primaryApplicant, [
            'mobile',
            'firstName',
            'lastName',
            'email',
            'preferredCommunicationMethods',
            'mailingAddress.state',
            'background',
          ])
        )
      } catch (error) {
        const err = error.response?.data.error || error
        console.error(err)
        this.$notify.error({
          title: 'Error creating user',
          message: err,
        })
        return false
      }

      form.finance.application.applicationType = isCommercial
        ? APPLICATION_REQUEST_TYPE_COMMERCIAL
        : APPLICATION_REQUEST_TYPE_CONSUMER

      // CREATE VEHICLE REQUEST GROUP
      const carLoanName = isCommercial
        ? LABEL_COMMERCIAL_CAR_LOAN
        : LABEL_CONSUMER_CAR_LOAN

      const authUser = rootState.auth.user
      const activeAccountRelation = authUser.accountRelations.find(
        isAccountRelationActiveReferrer
      )

      if (form.finance.application.loanType !== LABEL_PERSONAL_LOAN) {
        form.finance.application.loanType = carLoanName
      }
      // Force set to null, otherwise validation fails because the VRG endpoint doesn't expect vehicle data
      form.finance.application.vehicle = null
      form.tradeIn = null

      // form.account = createAccApiRes.data._id
      form.finance.application.primaryApplicantData.primaryApplicant =
        resCreateUser.data.user._id
      form.primaryUser =
        form.finance.application.primaryApplicantData.primaryApplicant

      form.finance.application.primaryUser =
        form.finance.application.primaryApplicantData.primaryApplicant

      if (
        form.finance.application.completedLeadApplicationAt === '' ||
        form.finance.application.completedLeadApplicationAt === null
      ) {
        delete form.finance.application.completedLeadApplicationAt
      }

      form.referrerUser = authUser._id

      if (activeAccountRelation && activeAccountRelation.account) {
        if (activeAccountRelation.account.parentAccount) {
          form.referrerAccount = activeAccountRelation.account.parentAccount
          form.referrerSubaccount = activeAccountRelation.account._id
        } else {
          form.referrerAccount = activeAccountRelation.account._id
        }
      }

      if (isQuickReferral) {
        form.referralMode = REFERRAL_MODE_QUICK
      } else {
        form.referralMode = REFERRAL_MODE_STANDARD
      }

      let checkvRGExists = false
      if (form._id) {
        const checkvRGExistsAPI = await this.$axios.get(
          `vehicle-request-groups/${form._id}`
        )

        if (checkvRGExistsAPI.data) {
          checkvRGExists = true
        }
      }

      let vrgAPIResponse
      if (checkvRGExists) {
        const updateVRGURL = `vehicle-request-groups/${form._id}`
        vrgAPIResponse = await this.$axios.patch(updateVRGURL, form)
      } else {
        const createVehicleRequestGroupAPIUrl = `vehicle-request-groups`
        vrgAPIResponse = await this.$axios.post(
          createVehicleRequestGroupAPIUrl,
          form
        )
      }

      const { data } = vrgAPIResponse
      data.finance.application.primaryApplicantData.primaryApplicant =
        resCreateUser.data.user
      // Copy over existing vehicle data
      data.finance.application.vehicle =
        vehicleRequestGroup.finance.application.vehicle
      data.tradeIn = { vehicle: vehicleRequestGroup.tradeIn.vehicle }

      commit('setProp', { key: 'referralApplication', value: data })

      const brokerNoteInteraction = {
        type: 'note',
        description: referrerNote,
        belongsTo: data._id,
        forModel: 'vehiclerequestgroup',
        summary: 'A note has been added',
        internal: false,
        visibleToCustomer: false,
        system: 'Accelerate',
        actor: `${authUser.firstName} ${authUser.lastName}`,
        vrgId: data._id,
        direction: 'inbound',
      }

      await fetch(`${process.env.VUE_APP_INTERACTION_API_URL}/interaction`, {
        method: 'POST',
        body: JSON.stringify(brokerNoteInteraction),
        headers: {
          'Content-Type': 'application/json',
          Authorization: process.env.VUE_APP_INTERACTION_KEY,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('Successfully sent referral', data)
        })

      return true
    } catch (error) {
      const err = error.response?.data.error || error
      console.error(err)
      this.$notify.error({
        title: 'Error',
        message: err,
      })
      return false
    }
  },
  async updateAssetDetails(
    { commit, dispatch },
    {
      vehicleData,
      tradeInData,
      vehicleRequestGroup,
      isCommercial,
      isQuickReferral = false,
      loanType,
      liabilitiesTotal,
    }
  ) {
    try {
      const vrg = cloneDeep(vehicleRequestGroup)
      const vrgUser = vrg.primaryUser
      const use = isCommercial ? VEHICLE_USE_BUSINESS : VEHICLE_USE_PERSONAL

      vrg.finance.application.applicationType = isCommercial
        ? APPLICATION_REQUEST_TYPE_COMMERCIAL
        : APPLICATION_REQUEST_TYPE_CONSUMER

      const createVehicleAPIUrl = 'vehicles'
      const doesVehicleExist = !!vehicleData?._id
      let createVehicleRes = null
      if (doesVehicleExist) {
        createVehicleRes = await this.$axios.patch(
          `/vehicles/${vehicleData?._id}`,
          {
            ...vehicleData,
            type: VEHICLE_TYPE_ASSET,
            user: vrgUser,
            use,
          }
        )
      } else {
        createVehicleRes = await this.$axios.post(createVehicleAPIUrl, {
          ...vehicleData,
          type: VEHICLE_TYPE_ASSET,
          user: vrgUser,
          use,
        })
      }

      // TRADE-IN VEHICLE
      let createNewTradeIn = true
      let tradeInRes
      const tradeInObj = {
        ...tradeInData,
        user: vrgUser,
        type: VEHICLE_TYPE_TRADE_IN,
        use,
      }

      if (tradeInData._id) {
        // CHECK TRADE-IN VEHICLE EXISTS
        const checkTradeInVehicleExists = `/vehicles/${tradeInData._id}`
        const checkTradeInVehicleExistsResponse = await this.$axios.get(
          checkTradeInVehicleExists
        )

        if (checkTradeInVehicleExistsResponse.data) {
          createNewTradeIn = false
          const updateTradeInVehicleAPIUrl = `/vehicles/${checkTradeInVehicleExistsResponse.data._id}`
          const updateTradeInVehicleAPIResponse = await this.$axios.patch(
            updateTradeInVehicleAPIUrl,
            tradeInObj
          )
          tradeInRes = updateTradeInVehicleAPIResponse.data

          commit('setProp', {
            key: 'referralApplication.tradeIn.vehicle',
            value: updateTradeInVehicleAPIResponse.data,
          })
        }
      }

      if (
        createNewTradeIn &&
        (tradeInData.code ||
          tradeInData.customerNote ||
          tradeInData.tradeInAmount)
      ) {
        // CREATE TRADE-IN VEHICLE
        const createTradeInRes = await this.$axios.post(
          createVehicleAPIUrl,
          tradeInObj
        )
        tradeInRes = createTradeInRes.data

        commit('setProp', {
          key: 'referralApplication.tradeIn.vehicle',
          value: createTradeInRes.data,
        })
      }

      // UPDATE VRG DATA
      const updateVRGUrl = `/vehicle-request-groups/${vrg._id}`
      set(vrg, 'finance.application.vehicle', createVehicleRes.data._id)
      set(vrg, 'tradeIn.vehicle', tradeInRes?._id)
      set(vrg, 'finance.application.loanType', loanType)

      if (tradeInRes?._id) {
        set(vrg, 'finance.application.tradeIn.vehicle', tradeInRes._id)
        const liabilityObj = {
          type: 'Vehicle Loan',
          name: '',
          lenderName: '',
          outstandingAmount: liabilitiesTotal,
          installmentAmount: 0,
          installmentFrequency: 'Monthly',
          loanYearsRemaining: '',
          loanMonthsRemaining: '',
          creditLimit: '',
          balloonAmount: '',
          interestRatePct: '',
          monthlyRepayment: '',
          repaymentHistoryDepiction: '',
          hasPayoutLetter: null,
          vehicle: tradeInRes._id,
        }
        set(
          vrg,
          'finance.application.primaryApplicantData.liabilities.0',
          liabilityObj
        )
      } else {
        const liabilityObj = {
          type: 'Vehicle Loan',
          name: '',
          lenderName: '',
          outstandingAmount: liabilitiesTotal,
          installmentAmount: 0,
          installmentFrequency: 'Monthly',
          loanYearsRemaining: '',
          loanMonthsRemaining: '',
          creditLimit: '',
          balloonAmount: '',
          interestRatePct: '',
          monthlyRepayment: '',
          repaymentHistoryDepiction: '',
          hasPayoutLetter: null,
          vehicle: null,
        }
        set(
          vrg,
          'finance.application.primaryApplicantData.liabilities.0',
          liabilityObj
        )
      }
      // Prefixing `$` to methods returns `data`.
      const updatevRGResponse = await this.$axios.$patch(updateVRGUrl, vrg)
      // Need to set the primary applicant to the object from the old VRG, since API doesn't return populated results
      updatevRGResponse.finance.application.primaryApplicantData.primaryApplicant =
        vrg.finance.application.primaryApplicantData.primaryApplicant
      updatevRGResponse.finance.application.vehicle = createVehicleRes.data
      updatevRGResponse.tradeIn = {
        vehicle: tradeInRes || DEFAULT_VEHICLE_OBJECT(),
      }

      if (isQuickReferral) {
        await dispatch('sendEsignRequestAction', {
          vrgId: vrg._id,
          applicationId: vrg.finance.application._id,
          applicantId: vrg.primaryUser,
        })
      }

      // SAVE DATA IN VUEX
      commit('setProp', {
        key: 'referralApplication',
        value: updatevRGResponse,
      })
      return true
    } catch (error) {
      const err = error.response?.data.error || error
      console.error(err)
      this.$notify.error({
        title: 'Error',
        message: err,
      })
    }
  },
  async updateSignedUrl(
    _vuexContext,
    { signedUrl = '/assets/signed-url', name, type, user, vrgId }
  ) {
    try {
      const signedRes = await this.$axios.post(signedUrl, {
        name,
        type,
        user,
        vrgId,
      })

      return signedRes
    } catch (error) {
      const err = error.response?.data.error || error
      console.error(err)
      this.$notify.error({
        title: 'Error',
        message: err,
      })
    }
  },
  async uploadDocument(
    { commit },
    { id, applicationId, applicantId, formData, isShared }
  ) {
    try {
      if (documentRequestSource) {
        documentRequestSource.cancel()
      }
      const documentRequestCancelToken = this.$axios.CancelToken
      documentRequestSource = documentRequestCancelToken.source()
      const subUrl = isShared ? `?isShared=${isShared}` : ''
      const requestUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/applicants/${applicantId}/documents/upload${subUrl}`
      await this.$axios.post(
        requestUrl,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
        {
          cancelToken: documentRequestSource.token,
        }
      )
      const requestUrlGet = `/vehicle-request-groups/${id}`
      const requests = await this.$axios.get(requestUrlGet)
      const { data } = requests

      commit(
        'updateDocumentData',
        data.finance.application.primaryApplicantData.documents
      )

      commit(
        'updateSelectedDocumentData',
        data.finance.application.primaryApplicantData.selectedDocuments
      )
      this.$notify.success({
        title: 'Success',
        message: 'File uploaded successfully!',
      })
    } catch (error) {
      if (!this.$axios.isCancel(error)) {
        const err =
          error.response?.data.error || axiosErrorMessageExtractor(error)
        this.$notify.error({
          title: 'Error',
          message: err,
        })
      }
    }
  },

  async updateDocuments(
    { commit },
    { documents, vehicleRequestGroup, loanType, selectedDocuments }
  ) {
    try {
      commit('setDocumentData', documents)
      commit('setSelectedDocumentData', selectedDocuments)
      const vrg = cloneDeep(vehicleRequestGroup)
      // Set vehicle data to just the IDs, as needed by the API
      set(
        vrg,
        'finance.application.vehicle',
        vrg.finance.application.vehicle._id
      )
      set(vrg, 'tradeIn.vehicle', vrg.tradeIn.vehicle._id || undefined)

      set(vrg, 'finance.application.loanType', loanType)

      const requestUrl = `/vehicle-request-groups/${vrg._id}`
      const requests = await this.$axios.patch(requestUrl, vrg)
      const { data } = requests

      commit(
        'updateDocumentData',
        data.finance.application.primaryApplicantData.documents
      )

      commit(
        'updateSelectedDocumentData',
        data.finance.application.primaryApplicantData.selectedDocuments
      )

      this.$notify.success({
        title: 'Success',
        message: 'File uploaded successfully!',
      })
    } catch (error) {
      const err = error.response?.data.error || error
      console.error(err)
      this.$notify.error({
        title: 'Error',
        message: err,
      })
    }
  },
  async removeUploadedDocument(
    { commit },
    { filename, vehicleRequestGroup, loanType, name }
  ) {
    try {
      commit('removeDocument', filename)
      commit('removeSelectedDocument', name)
      const vrg = cloneDeep(vehicleRequestGroup)
      // Set vehicle data to just the IDs, as needed by the API
      set(
        vrg,
        'finance.application.vehicle',
        vrg.finance.application.vehicle._id
      )
      set(vrg, 'tradeIn.vehicle', vrg.tradeIn.vehicle._id || undefined)

      set(vrg, 'finance.application.loanType', loanType)

      const requestUrl = `/vehicle-request-groups/${vrg._id}`
      await this.$axios.patch(requestUrl, vrg)

      this.$notify.success({
        title: 'Success',
        message: 'Document removed successfully',
      })
    } catch (error) {
      const err = error.response?.data.error || error
      console.error(err)
      this.$notify.error({
        title: 'Error',
        message: err,
      })
    }
  },
  setDefaultState({ commit }) {
    try {
      commit(
        'setNewReferralApplication',
        DEFAULT_VEHICLE_GROUP_OBJECT({ applicationType: '' })
      )
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: 'Error in updating data!',
      })
    }
  },
  async fetchReferralsForkanban(
    { commit, state },
    {
      status = 'All',
      currentPage = 1,
      search = '',
      type = 'All',
      sort = '',
      reset = false,
    }
  ) {
    try {
      if (source) {
        source.cancel()
      }
      const CancelToken = this.$axios.CancelToken
      source = CancelToken.source()

      let requestUrl = '/vehicle-request-groups/advance-search?'
      if (status !== 'All') {
        requestUrl += '&status=' + status
      }
      if (type !== 'All') {
        requestUrl += '&type=' + type
      }
      if (sort) {
        requestUrl += '&sort=' + sort
      }
      if (currentPage) {
        requestUrl += '&page=' + currentPage
      }
      if (search) {
        requestUrl += '&search=' + encodeURIComponent(search)
      }
      requestUrl += '&isAPICalledForKanBanView=true'

      let searchDetail = { referral: {}, customer: {}, loanDetails: {} }
      for (const i in state.advancedSearchDetail) {
        if (ADVANCED_SEARCH_REFERRAL.includes(i)) {
          searchDetail.referral[i] = state.advancedSearchDetail[i]
        } else if (ADVANCED_SEARCH_REFERRAL_FIRST.includes(i)) {
          var obj = {
            endDate: state.advancedSearchDetail[i][1],
            startDate: state.advancedSearchDetail[i][0],
          }
          searchDetail.referral[i] = obj
        } else if (ADVANCED_SEARCH_CUSTOMER.includes(i)) {
          searchDetail.customer[i] = state.advancedSearchDetail[i]
        } else if (ADVANCED_SEARCH_LOAN_DETAILS.includes(i)) {
          searchDetail.loanDetails[i] = state.advancedSearchDetail[i]
        }
      }

      const requests = await this.$axios({
        method: 'POST',
        url: requestUrl,
        headers: {},
        data: searchDetail,
      })
      if (currentPage <= 1) {
        let totalReferrals = 0
        const { data } = requests
        for (const i in data) {
          if (data[i]?.totalDocs) {
            totalReferrals += data[i]?.totalDocs
          }
        }
        commit('setTableSettings', {
          totalDocs: totalReferrals,
        })
      }
      if (!reset) {
        let tableData = requests.data[status].docs.map(mapVRGForTable)
        requests.data[status].docs =
          state.referralListData[status].docs.concat(tableData)

        const docsData = JSON.parse(JSON.stringify(requests.data[status]))

        delete docsData['docs']

        commit('updateReferralListData', {
          data: { docs: requests.data[status].docs, ...docsData },
          status,
        })
      }

      if (reset && state.referralListData) {
        Object.keys(requests.data).forEach((key) => {
          if (requests.data[key].docs.length) {
            const tableData = requests?.data[key]?.docs?.map(mapVRGForTable)
            requests.data[key].docs = tableData
            requests.data[key].docs = orderBy(
              tableData,
              'buying.requests.completedLeadApplicationAt',
              'desc'
            )
          }
        })
        const { data } = requests
        commit('resetReferralListData', data)
      }
    } catch (error) {
      if (error?.message === 'Network Error') {
        commit('resetReferralListDataHasNextPage', {
          data: false,
          status,
        })
        this.$notify.error({
          title: 'Error',
          message: axiosErrorMessageExtractor(error),
        })
      }
      if (error?.message && error?.message !== 'Network Error') {
        this.$notify.error({
          title: 'Error',
          message: axiosErrorMessageExtractor(error),
        })
      }
    } finally {
      commit('stopTableLoading')
    }
  },
  async fetchReferrals(
    { commit, state },
    {
      status = 'All',
      currentPage = 1,
      search = '',
      type = 'All',
      sort = '',
      reset = false,
    }
  ) {
    try {
      if (source) {
        source.cancel()
      }
      const CancelToken = this.$axios.CancelToken
      source = CancelToken.source()

      let requestUrl = '/vehicle-request-groups/advance-search?'
      if (status !== 'All') {
        requestUrl += '&status=' + status
      }
      if (type !== 'All') {
        requestUrl += '&type=' + type
      }
      if (sort) {
        requestUrl += '&sort=' + sort
      }
      if (currentPage) {
        requestUrl += '&page=' + currentPage
      }
      if (search) {
        requestUrl += '&search=' + encodeURIComponent(search)
      }
      requestUrl += '&isAPICalledForKanBanView=false'

      let searchDetail = { referral: {}, customer: {}, loanDetails: {} }
      for (const i in state.advancedSearchDetail) {
        if (ADVANCED_SEARCH_REFERRAL.includes(i)) {
          searchDetail.referral[i] = state.advancedSearchDetail[i]
        } else if (ADVANCED_SEARCH_REFERRAL_FIRST.includes(i)) {
          var obj = {
            endDate: state.advancedSearchDetail[i][1],
            startDate: state.advancedSearchDetail[i][0],
          }
          searchDetail.referral[i] = obj
        } else if (ADVANCED_SEARCH_CUSTOMER.includes(i)) {
          searchDetail.customer[i] = state.advancedSearchDetail[i]
        } else if (ADVANCED_SEARCH_LOAN_DETAILS.includes(i)) {
          searchDetail.loanDetails[i] = state.advancedSearchDetail[i]
        }
      }
      commit('startTableLoading')
      const requests = await this.$axios({
        method: 'POST',
        url: requestUrl,
        headers: {},
        data: searchDetail,
      })

      const { data } = requests
      commit('setTableSettings', {
        totalDocs: data.totalDocs,
        page: data.page,
        totalPages: data.totalPages,
        limit: data.limit,
        pagingCounter: data.pagingCounter,
      })
      let tableData = requests.data.docs.map(mapVRGForTable)

      tableData = orderBy(
        tableData,
        'buying.requests.completedLeadApplicationAt',
        'desc'
      )
      if (reset) {
        commit('resetReferralList', tableData)
      } else {
        commit('setReferralList', tableData)
      }
    } catch (error) {
      if (error?.message) {
        this.$notify.error({
          title: 'Error',
          message: axiosErrorMessageExtractor(error),
        })
      }
    } finally {
      commit('stopTableLoading')
    }
  },
  async fetchSettlements(
    { commit, state },
    {
      status = 'All',
      currentPage = 1,
      search = '',
      type = 'All',
      sort = '',
      reset = false,
    }
  ) {
    try {
      let requestUrl = '/vehicle-request-groups/advance-search?status=Won'
      if (status !== 'All') {
        requestUrl += '&status=' + status
      }
      if (type !== 'All') {
        requestUrl += '&type=' + type
      }
      if (sort) {
        requestUrl += '&sort=' + sort
      }
      if (currentPage) {
        requestUrl += '&page=' + currentPage
      }
      if (search) {
        requestUrl += '&search=' + encodeURIComponent(search)
      }

      let searchDetail = {
        referral: { status: ['Won'] },
        customer: {},
        loanDetails: {},
      }
      for (const i in state.advancedSearchDetail) {
        if (ADVANCED_SEARCH_REFERRAL.includes(i)) {
          searchDetail.referral[i] = state.advancedSearchDetail[i]
        } else if (ADVANCED_SEARCH_REFERRAL_FIRST.includes(i)) {
          var obj = {
            endDate: state.advancedSearchDetail[i][1],
            startDate: state.advancedSearchDetail[i][0],
          }
          searchDetail.referral[i] = obj
        } else if (ADVANCED_SEARCH_CUSTOMER.includes(i)) {
          searchDetail.customer[i] = state.advancedSearchDetail[i]
        } else if (ADVANCED_SEARCH_LOAN_DETAILS.includes(i)) {
          searchDetail.loanDetails[i] = state.advancedSearchDetail[i]
        }
      }

      commit('startTableLoading')

      const requests = await this.$axios({
        method: 'POST',
        url: requestUrl,
        headers: {},
        data: searchDetail,
      })
      const { data } = requests

      commit('setTableSettings', {
        totalDocs: data.totalDocs,
        page: data.page,
        totalPages: data.totalPages,
        limit: data.limit,
        pagingCounter: data.pagingCounter,
      })

      commit('setAvgNAFAndTotalCommission', {
        avgNAF: data.avgNAF,
        totalCommission: data.totalCommission,
      })
      let tableData = requests.data.docs.map((vrg) => mapVRGForTable(vrg, true))
      // eslint-disable-next-line no-undef
      tableData = orderBy(
        tableData,
        'buying.requests.completedLeadApplicationAt',
        'desc'
      )
      if (reset) {
        commit('reSetSettlementList', tableData)
      } else {
        commit('setSettlementList', tableData)
      }
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('stopTableLoading')
    }
  },
  clearReferralList({ commit }) {
    commit('clearReferralList')
  },
  clearReferralListData({ commit }) {
    commit('clearReferralListData')
  },
  clearSettlementList({ commit }) {
    commit('clearSettlementList')
  },
  updateReferralApplication({ commit }, { application, loanType = '' }) {
    if (loanType) {
      application.finance.application.loanType = loanType
    }
    commit('setNewReferralApplication', application)
  },
  async sendEsignRequestAction({}, { vrgId, applicationId, applicantId }) {
    try {
      const requestUrl = `/vehicle-request-groups/${vrgId}/finance/applications/${applicationId}/applicants/${applicantId}/esign-requests`
      await this.$axios.post(requestUrl)
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: 'Error in sending esign requests',
      })
    }
  },
  async advancedSearchDetail(
    { commit, dispatch },
    { id, applicationId, advancedSearchDetail }
  ) {
    try {
      commit('setadvancedSearchDetail', advancedSearchDetail)
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
}
